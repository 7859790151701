<template>
    <Loader v-if="loader"/>
    <CHeader position="sticky">
        <CContainer fluid>
            <CContainer fluid>
                <CRow class="mt-2 justify-content-between">
                    <CCol class="p-0">
                        <CDropdown class="mobile-nav d-sm-none">
                            <CDropdownToggle :caret="false">
                                <button class="btn btn-light">
                                    <font-awesome-icon icon="fa-solid fa-bars"></font-awesome-icon>
                                </button>
                            </CDropdownToggle>
                            <CDropdownMenu>
                                <CDropdownItem v-for="navigationItem in navigationItems">
                                    <CNavItem>
                                        <CNavLink :href="navigationItem.to">
                                            <img :alt="navigationItem.name" :src="`/images/${navigationItem.icon}.svg`">
                                            {{navigationItem.name}}
                                        </CNavLink>
                                    </CNavItem>
                                </CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                        <div class="d-inline-flex align-items-center header-title">
                            <AppBreadcrumb/>
                        </div>
                    </CCol>
                    <CCol class="d-flex justify-content-end align-items-center">
                        <CNavLink
                                  @click="logout()"
                                  class="logout clickable">
                            <svg width="16px" height="12px" viewBox="0 0 15 15" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5 7.5L10.5 10.75M13.5 7.5L10.5 4.5M13.5 7.5L4 7.5M8 13.5H1.5L1.5 1.5L8 1.5"
                                      stroke="#000000"/>
                            </svg>
                        </CNavLink>
                    </CCol>
                </CRow>
            </CContainer>
        </CContainer>
        <!-- <CHeaderDivider /> -->
    </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import axios from "axios";
import {useRouter} from "vue-router";
import {SERVICE_URL} from "@/serviceResolver";
import {AppSidebarNav} from "./AppSidebarNav";
import nav from '../_nav.js'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: 'AppHeader',
    emits: ['search'],
    props: ['showSearchBar'],
    components: {
        FontAwesomeIcon,
        AppSidebarNav,
        AppBreadcrumb,
    },
    data() {
        return {
            loader: false,
            navigationItems: nav,
            // searchInput: null
        }
    },
    setup() {
        const router = useRouter();
        const logout = async () => {
            try {
                await axios.post(`${SERVICE_URL}/user/logout`);
                localStorage.removeItem('authToken');
                router.push({name: 'Login'});
            } catch (error) {
                console.error("Error:", error);
            }
        }

        return {
            logout
        };
    },
    // watch: {
    //     searchInput: function(value) {
    //         this.$emit('search', value);
    //     }
    // }
}
</script>

<style>
.mobile-nav .sidebar-nav .nav-item  {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mobile-nav .btn {
    border-bottom: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}
</style>