import currency from "currency.js";
import * as moment from 'moment';

export function copyToClipboard(text) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    this.$notify({type: "success", text: "Link Copied"});
}

export function focusInputField(field) {
    this.$nextTick(() => {
        this.$refs[field].$el.nextElementSibling.focus();
    })
}

export function isLocalStorageAvailable(){
    const test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

export function renderCurrency(currency, locales = 'en-GB') {
    const formatter = Intl.NumberFormat(locales, {
        style: 'currency',
        currency: 'GBP',
    });

    return formatter.format(currency);
}

export function renderCurrencyFromCents(currencyInCents) {
    return currency(currencyInCents, { fromCents: true }).format({ symbol: '£'})
}

export function renderPaymentMethodIcon(paymentMethod) {
    if(isCardPayment(paymentMethod)) {
        return 'fa-credit-card'
    } else if(paymentMethod === 'directdebit_GB') {
        return 'fa-repeat'
    } else if(paymentMethod === 'paybybank') {
        return 'fa-building-columns'
    } else {
        return 'fa-circle-question'
    }
}

export function renderPaymentMethodName(paymentMethod) {
    if (isCardPayment(paymentMethod)) {
        return 'Card'
    } else if (paymentMethod === 'directdebit_GB') {
        return 'DD'
    } else if (paymentMethod === 'paybybank') {
        return 'Bank'
    } else if (paymentMethod === 'null') {
        return 'None'
    } else {
        return 'Unknown'
    }
}

function isCardPayment(paymentMethod) {
    return paymentMethod && (paymentMethod === 'card' || paymentMethod.startsWith('mc') || paymentMethod.startsWith('visa') || paymentMethod.startsWith('amex'))
}

export function renderStatus(status) {
    return renderEnumValue(status);
}

export function renderEnumValue(value) {
    return value.substring(0,1).toUpperCase() +
        value.substring(1).toLowerCase().replaceAll('_', ' ');
}

export function renderTime(isoString) {
    return moment(isoString).format('HH:mm')
}

export function renderDateTime(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const formattedDate = `${day}/${month} ${hours}:${minutes}`;
    return formattedDate;
}

export function renderDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");

    const formattedDate = `${day}/${month}`;
    return formattedDate;
}

export function renderDateToString(dateObject) {
    if (!dateObject) return '';
    return dateObject.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    });
}

export function renderFullDate(dateObject) {
    const date = new Date(dateObject);
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };
    return date.toLocaleString('en-GB', options);
}
