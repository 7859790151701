<template>
  <div class="spinner-container">
    <div class="spinner-border" role="status">
<!--      <span class="sr-only">Loading...</span>-->
    </div>
  </div>
</template>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* or absolute, depending on your layout */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 9999999; /* Ensure it's above other content */
}

.spinner-border {
  /* Your existing styles for spinner */
}
</style>
