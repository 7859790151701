<template >
    <CCol class="text-center align-items-center py-3" style="background-color: #EBF0F0;">
        <span>
            <font-awesome-icon
                icon="fa fa-calculator"
                class="clickable me-2"
                size="lg"/>
            Card Terminals
        </span>
        <CRow v-if="loading">
            <font-awesome-icon
                icon="fa fa-circle-notch"
                class="me-2" spin/>
            <i class="text-warning">Loading</i>
        </CRow>
        <CRow class="justify-content-center" v-else>
            <i class="text-danger" v-if="terminalsError">
               {{terminalsError}}
            </i>
            <CCol class="col-6" v-else-if="terminals" v-for="terminal in terminals" :key="terminal.id">
                <CardTerminal
                    :terminal="terminal"
                    :transaction-uuid="transactionUuid"
                    :allow-log-download="allowLogDownload"></CardTerminal>
                <CRow class="justify-content-center" v-if="allowLogDownload">
                </CRow>
            </CCol>
            <i class="text-danger" v-else>Error fetching terminals.</i>
        </CRow>
    </CCol>
</template>

<script>
import '@adyen/adyen-web/dist/adyen.css';
import axios from "axios";
import {SERVICE_URL} from "@/serviceResolver";
import CardTerminal from "@/components/terminal/CardTerminal.vue";

export default {
    name: 'TerminalSelector',
    components: {CardTerminal},
    props: ['transactionUuid', 'allowLogDownload'],
    data() {
        return {
            error: null,
            isRemoteTransaction: false,
            log: null,
            loading: true,
            terminals: [],
            terminalsError: null,
        }
    },
    methods: {
        async fetchAndSetTerminals() {
            try {
                const terminalsResponse = await axios.get(`${SERVICE_URL}/terminal`);
                if (terminalsResponse.data) {
                    if (terminalsResponse.data.length === 0) {
                        this.terminalsError = "You haven't logged into any terminals yet."
                    } else {
                        this.terminals = terminalsResponse.data;
                    }
                } else {
                    this.terminalsError = "Error fetching terminals."
                }
            } catch (e) {
                console.error(e)
                this.terminalsError = e
            } finally {
                this.loading = false;
            }
        }
    },
    async mounted() {
        await this.fetchAndSetTerminals();
    },
};
</script>
