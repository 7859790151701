<template>
    <CSidebar position="fixed">
        <CSidebarBrand class="mb-3" @click="this.$router.push('/')">
            <CRow class="col-12 clickable align-content-center justify-content-center align-items-center">
                <img
                    alt="Coherent logo"
                    class="sidebar-brand-full"
                    style="height: 100px; width: 100px;"
                    src="/images/elephant-circle.svg"/>
            </CRow>
        </CSidebarBrand>
        <AppSidebarNav v-if="merchant && merchant.paymentProviderType !== 2"></AppSidebarNav>
        <AppSidebarNavDD v-else></AppSidebarNavDD>
    </CSidebar>
</template>

<script>
import {AppSidebarNav} from './AppSidebarNav'
import {AppSidebarNavDD} from './AppSidebarNavDD'
import axios from "axios";
import {SERVICE_URL} from "@/serviceResolver";

export default {
    name: 'AppSidebar',
    components: {
        AppSidebarNav,
        AppSidebarNavDD
    },
    data() {
        return {
            merchant: null
        }
    },
    mounted() {
        axios.get(`${SERVICE_URL}/user`)
            .then(userResponse => {
                this.merchant = userResponse.data.user.merchant;
            })
    }
}
</script>
