<template>
  <CFooter>
    <div>
        <span class="copyright">&copy; {{ new Date().getFullYear() }} All rights reserved.</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
