<template>
    <div>
        <AppSidebar/>
        <div class="wrapper d-flex flex-column min-vh-100 bg-light">
            <AppHeader />
            <div class="body d-flex flex-column flex-grow-1 px-3">
                <!-- <router-view :search-input="searchInput"/> -->
                <router-view />
            </div>
            <AppFooter/>
        </div>
    </div>
</template>
<script>
import {CContainer} from '@coreui/vue'
import AppFooter from '../components/AppFooter.vue'
import AppHeader from '../components/AppHeader.vue'
import AppSidebar from '../components/AppSidebar.vue'
import transactionsPopup from "@/components/TransactionsPopup.vue";

export default {
    name: 'DefaultLayout',
    components: {
        transactionsPopup,
        AppFooter,
        AppHeader,
        AppSidebar,
        CContainer,
    },
    data() {
        return {
            // searchInput: null,
            showMobileNav: false
        }
    },
    methods: {
        // search(searchInput) {
        //     this.searchInput = searchInput;
        // }
    }
}
</script>
