export default [
    {
        component: 'CNavItem',
        name: 'Till',
        to: '/dashboard/till',
        icon: 'till',
    },
    {
        component: 'CNavItem',
        name: 'Direct Debits',
        to: '/dashboard/direct-debits',
        icon: 'till',
    },
    {
        component: 'CNavItem',
        name: 'Transactions',
        to: '/dashboard/transactions',
        icon: 'transactions',
    },
    {
        component: 'CNavItem',
        name: 'Summary',
        to: '/dashboard/summary',
        icon: 'dashboard',
        // badge: {
        //   color: 'primary',
        //   text: 'NEW',
        // },
    },
    {
        component: 'CNavItem',
        name: 'Support',
        to: '/dashboard/support',
        icon: 'support',
    }
]
