import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDcXHnanQrF9UCM0fCVz4M3Kp6GVYXHwZQ",
    authDomain: "coherent-app-b6257.firebaseapp.com",
    projectId: "coherent-app-b6257",
    storageBucket: "coherent-app-b6257.appspot.com",
    messagingSenderId: "499887122783",
    appId: "1:499887122783:web:60deace60d6c901b50aeea",
    measurementId: "G-V4K8EVHHNL"
};

const firebaseApp = initializeApp(firebaseConfig);

export default {
    install(app) {
        app.provide('firebase', firebaseApp);
    },
};