let serviceUrl = "https://service.coherenthealthcare.com"

if(window.location.origin.includes("localhost")) {
    serviceUrl = window.location.origin.replace("8080", "3000")
} else if(window.location.origin.includes("coherenthealthcare.com")) {
    serviceUrl = window.location.origin
        .replace('preview.', '')
        .replace('app.','service.')
} else if(window.location.origin.includes(".web.app")) { // This is PR preview, send to test
    serviceUrl = "https://service.test.coherenthealthcare.com"
}

export const SERVICE_URL = serviceUrl