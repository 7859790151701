import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

//PrimeVue CSS files required
import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/aura-light-green/theme.css";

import CoreuiVue from '@coreui/vue'
import PrimeVue from 'primevue/config';
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import axios from "axios";
import Loader from "@/components/Loader.vue";
import {SERVICE_URL} from "@/serviceResolver";
import Notifications from '@kyvg/vue3-notification'
import { library as fontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FirebasePlugin from './plugins/firebase';
import {
    faAt,
    faBackspace,
    faBars,
    faBroom,
    faBuildingColumns,
    faCalculator,
    faCaretDown,
    faCheck,
    faCircleCheck,
    faCircleNotch,
    faCircleQuestion,
    faCircleXmark,
    faClock,
    faCreditCard,
    faCreditCardAlt,
    faEraser,
    faEye,
    faEyeSlash,
    faFileExport,
    faFilter,
    faLink,
    faPause,
    faPaperPlane,
    faPenToSquare,
    faPlay,
    faPlus,
    faPrint,
    faRotate,
    faRepeat,
    faSms,
    faSort,
    faSquareMinus,
    faSquarePlus,
    faTrashCan,
    faUndo,
    faXmark
} from '@fortawesome/free-solid-svg-icons'
import VueApexCharts from "vue3-apexcharts";

//PrimeVue components
import Button from "primevue/button";
import Calendar from 'primevue/calendar';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import MultiSelect from 'primevue/multiselect';
import ProgressSpinner from 'primevue/progressspinner';
import ScrollPanel from "primevue/scrollpanel";
import SelectButton from 'primevue/selectbutton';
import Sidebar from "primevue/sidebar";

fontAwesomeLibrary.add(faAt)
fontAwesomeLibrary.add(faBackspace)
fontAwesomeLibrary.add(faBars)
fontAwesomeLibrary.add(faBroom)
fontAwesomeLibrary.add(faBuildingColumns)
fontAwesomeLibrary.add(faCalculator)
fontAwesomeLibrary.add(faCaretDown)
fontAwesomeLibrary.add(faCheck)
fontAwesomeLibrary.add(faCircleCheck)
fontAwesomeLibrary.add(faCircleNotch)
fontAwesomeLibrary.add(faCircleQuestion)
fontAwesomeLibrary.add(faCircleXmark)
fontAwesomeLibrary.add(faClock)
fontAwesomeLibrary.add(faCreditCard)
fontAwesomeLibrary.add(faCreditCardAlt)
fontAwesomeLibrary.add(faEraser)
fontAwesomeLibrary.add(faEye)
fontAwesomeLibrary.add(faEyeSlash)
fontAwesomeLibrary.add(faFileExport)
fontAwesomeLibrary.add(faFilter)
fontAwesomeLibrary.add(faLink)
fontAwesomeLibrary.add(faPaperPlane)
fontAwesomeLibrary.add(faPause)
fontAwesomeLibrary.add(faPenToSquare)
fontAwesomeLibrary.add(faPlay)
fontAwesomeLibrary.add(faPlus)
fontAwesomeLibrary.add(faPrint)
fontAwesomeLibrary.add(faRepeat)
fontAwesomeLibrary.add(faRotate)
fontAwesomeLibrary.add(faSort)
fontAwesomeLibrary.add(faSms)
fontAwesomeLibrary.add(faSquareMinus)
fontAwesomeLibrary.add(faSquarePlus)
fontAwesomeLibrary.add(faTrashCan)
fontAwesomeLibrary.add(faUndo)
fontAwesomeLibrary.add(faXmark)

const app = createApp(App)
app.use(router)
app.use(CoreuiVue)
app.use(PrimeVue, { ripple: true });
app.use(ConfirmationService);
app.use(Notifications)
app.use(FirebasePlugin)
app.use(VueApexCharts);
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('Loader', Loader);
app.component('font-awesome-icon', FontAwesomeIcon);

// PrimeVue components
app.component('Button', Button);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('SelectButton', SelectButton);
app.component('MultiSelect', MultiSelect);
app.component('Sidebar', Sidebar);
app.component('ScrollPanel', ScrollPanel);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Calendar', Calendar);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('ProgressSpinner', ProgressSpinner);

app.mount('#app');

async function initializeApp() {
    try {
        const token = localStorage.getItem('authToken');
        if (token) {
            axios.defaults.headers.common['access-token'] = token;
            await axios.get(`${SERVICE_URL}/init`);
        }
    } catch (error) {
        axios.defaults.headers.common['access-token'] = null;
        localStorage.removeItem('authToken');
        router.push({ name: 'Login' });
    }
}

initializeApp();
